import { Stack, Typography, useMediaQuery } from "@mui/material"
import React, { useState, useEffect } from "react"

function DateTimeUpdate() {
  const [currentDateTime, setCurrentDateTime] = useState(new Date())

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date())
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  const formattedDate = currentDateTime.toLocaleDateString()
  const formattedTime = currentDateTime.toLocaleTimeString()
  const timeZone = currentDateTime.toString().match(/\(([^)]+)\)/)[1]

  const isMobile = useMediaQuery("(max-width:980px)")

  return (
    <div>
      <Stack
        direction='column'
        alignItems={isMobile ? "center" : "flex-start"} // Center in mobile (xs) screens, left-align in other (sm) screens
      >
        <Typography variant='h7'>
          {formattedDate} {formattedTime}
        </Typography>
        <Typography variant='h7'>{timeZone}</Typography>
      </Stack>
    </div>
  )
}

export default DateTimeUpdate

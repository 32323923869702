export function getNextCallNumber(array) {
    if (array.length === 0) {
        // If the array is empty, start with '000001'
        return '000001';
    }

    // Find the maximum callNumber in the array
    const maxCallNumber = array.reduce((max, obj) => {
        const currentCallNumber = parseInt(obj.callNumber, 10);
        return currentCallNumber > max ? currentCallNumber : max;
    }, 0);

    // Increment the maximum callNumber to generate the next one
    const nextCallNumber = (maxCallNumber + 1).toString().padStart(6, '0');
    return nextCallNumber;
}
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

function GenericAccordion({ title, component }) {
  return (
    <Accordion
      sx={{
        boxShadow: "none",
      }}
    >
      <AccordionSummary
        sx={{
          bgcolor: "#1D460A",
          width: "auto",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography
          sx={{ textTransform: "uppercase", fontWeight: "medium" }}
          flexGrow={1}
          textAlign={"center"}
          color={"white"}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{component}</AccordionDetails>
    </Accordion>
  )
}

export default GenericAccordion

import logo from './logo.svg';
import './App.css';
import MainPage from './pages/MainPage';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { themeOptions } from './theme/CustomTheme';

function App() {
  const theme = createTheme(themeOptions);
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainPage />
        <ToastContainer />
      </ThemeProvider>
    </>
  );
}

export default App;

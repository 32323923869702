import { Stack, useMediaQuery } from "@mui/material"
import TableSection from "../components/tableSection/TableSection"
import CallForm from "../components/callForm/CallForm"
import { ENDPOINTS, createAPIEndpoint } from "../api/api"
import { useEffect, useState } from "react"
import CallFormMobile from "../components/callForm/CallFormMobile"

function MainPage() {
  const [allQueueItems, setAllQueueItems] = useState([])
  const [selectedQueueItem, setSelectedQueueItem] = useState({})

  useEffect(() => {
    refetchItems()
  }, [])

  const refetchItems = () => {
    createAPIEndpoint(ENDPOINTS.call)
      .fetch()
      .then((res) => {
        const updatedData = res.data.map((item) => {
          const parsedIncidentAddress = JSON.parse(
            item.incidentAddress === "" ? "{}" : item.incidentAddress
          )
          return { ...item, incidentAddress: parsedIncidentAddress }
        })
        setAllQueueItems(updatedData)
      })
  }

  const isMobile = useMediaQuery("(max-width:980px)")

  return (
    <>
      <Stack direction={"column"}>
        {selectedQueueItem && (
          <TableSection
            queueItems={allQueueItems}
            setSelectedQueueItem={setSelectedQueueItem}
            selectedQueueItem={selectedQueueItem}
            refetchItems={refetchItems}
          />
        )}
        {isMobile ? (
          <CallFormMobile
            selectedQueueItem={selectedQueueItem}
            setSelectedQueueItem={setSelectedQueueItem}
            refetchItems={refetchItems}
          />
        ) : (
          <CallForm
            selectedQueueItem={selectedQueueItem}
            setSelectedQueueItem={setSelectedQueueItem}
            refetchItems={refetchItems}
          />
        )}
      </Stack>
    </>
  )
}

export default MainPage

const statusValues = ["DSP", "ENR", "ARR", "DPT", "ARD", "CLR", "AVA"]

const callSources = [
    '9-1-1 Call',
    'Non-Emergency Line',
    '3rd Party Transfer',
    'Police',
    'EMS',
    'Fire',
    'Bell Operator',
    'Crew Member',
    'Walk-In',
];

const agencies = [
    'Police Services',
    'Fire Services',
    'Emergency Medical Services',
    'Non-Emergency Services',
];

const callSource = [
    '9-1-1 Call',
    'Non-Emergency Line',
    '3rd Party Transfer',
    'Police',
    'Fire',
    'Bell Operator',
    'Crew Member',
    'Walk-In',
    'EMS'
];

const policeServicesPriority = ['Emergency', 'Urgent', 'Moderate', 'NonEmergent'];

const fireServicePriority = ['High Priority', 'Non-Emergency'];

const emergencyMedicalServicesPriority = [
    'Priority (Code 4 / Echo, Delta)',
    'Priority (Code 3 / Charlie, Bravo)',
    'Non-emergent (Code 2 / Alpha)',
];

const nonEmergencyPriority = [
    'Immediate Response',
    'Delayed Response',
    'Scheduled Response',
    'Call Referral / Information Only',
];

const policeServiceIncidents = [
    'Abduction/Kidnapping',
    'Active Shooter',
    'Aircraft Emergencies',
    'Alarms',
    'Assault & Battery',
    'Assault & Battery – Gunshot/Stabbing',
    'Bomb Threats – 1st Party',
    'Bomb Threats – 3rd Party',
    'Break & Enter',
    'Break & Enter – In-Progress',
    'Carjacking',
    'Child-Found/Lost',
    'Deceased Person – Natural or Suspicious',
    'Disabled Vehicle',
    'Disorderly Conduct',
    'Disturbance (Verbal/Fight/Family)',
    'Escaped Prisoner',
    'Fraud/Forgery',
    'Holdup/Robbery',
    'Holdup Alarm',
    'Home Invasion',
    'Homicide/Murder',
    'Hostage Situations',
    'Impaired Driver',
    'Missing Person',
    'MVC',
    'MVC – Hit & Run',
    'Narcotics – Activity',
    'Obscene Phone Calls',
    'Officer Down',
    'Prowler',
    'Sexual Assault',
    'Shoplifting',
    'Stalking',
    'Stolen Vehicle',
    'Suicide (Attempted)',
    'Theft',
    'Theft from a Vehicle',
    'Threats',
    'Trespassers',
    'Vandalism',
];

const fireServiceIncidents = [
    'Brush and Wildland Fires',
    'Structure Fire',
    'Structure Fire – High-rise Building',
    'Vehicle Fire',
    'Aircraft Fire/Emergency',
    'Building Collapse',
    'Elevator Rescue',
    'High Angle Rescue',
    'Industrial Entrapment',
    'Motor Vehicle Collision',
    'Search and Rescue',
    'Submerged/Sinking Vehicle',
    'Trench Rescue',
    'Water Rescue',
    'Biological Incident',
    'Chemical Incident',
    'Explosive Incident',
    'HAZMAT Incident',
    'Nuclear Incident',
    'Propane Incident',
    'Fire Alarm',
    'Carbon Monoxide Alarm',
    'Bomb Threat',
    'Bomb Threat',
    'Fuel Spill',
    'Fumes/Gas Leak',
    'Incendiary Incidents',
    'Power Lines/Wires Down',
    'Suspicious Package/Letter',
];

const emergencyMedicalIncidents = [
    'Abdominal/Back Pain',
    'Allergic Reaction',
    'Animal Bite',
    'Bleeding',
    'Breathing Difficulty',
    'Burns',
    'Chest Pain/Heart Problem',
    'Choking',
    'Diabetic',
    'Environmental Emergencies',
    'Eye Problem/Injury',
    'Falls',
    'Gynecology/Childbirth',
    'Headache',
    'Industrial Accident',
    'Mental/Emotional/Psychological',
    'Motor Vehicle Collision (MVC)',
    'Near Drowning/Diving Accident',
    'Neurological/Head Injuries',
    'Overdose/Poisoning',
    'Seizures',
    'Sick/Unknown',
    'Stabbing/Gunshot',
    'Stroke (CVA)',
    'Trauma',
    'Unconscious/Unresponsive/Syncope',
    'CPR – Adult',
    'CPR – Child',
    'CPR - Infant',
    'CPR – Laryngectomy and Tracheostomy',
    'Obstructed Airway - Adult',
    'Obstructed Airway – Child',
    'Obstructed Airway - Infant',
    'Childbirth',
    'Airway Control',
    'Assault'
];

const nonEmergencyServiceIncidents = [
    'Administrative',
    'Educational',
    'Recreational',
    'Parks Department',
    'Works Department',
    'Municipal Bylaw Issue',
    'Animal Control',
    'Information Request',
    'General Assistance',
];

export {
    statusValues,
    callSource,
    agencies,
    callSources,
    policeServicesPriority,
    fireServicePriority,
    emergencyMedicalServicesPriority,
    nonEmergencyPriority,
    policeServiceIncidents,
    fireServiceIncidents,
    emergencyMedicalIncidents,
    nonEmergencyServiceIncidents,
};

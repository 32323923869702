export function getCurrentDateTimeFormatted() {
    const months = [
        "Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ];

    const now = new Date();
    const day = now.getDate();
    const month = months[now.getMonth()];
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Pad the day and minutes with leading zeros if necessary
    const formattedDay = (day < 10 ? "0" : "") + day;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    const formattedDateTime = `${formattedDay}-${month}-${year} ${hours}:${formattedMinutes}`;

    return formattedDateTime;
}


import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import logo from "../../assets/Free Cad Logo.jpg"
import DateTimeUpdate from "./DateTimeUpdate"
import React, { useEffect, useState } from "react"
import GoogleMapAutocomplete from "../formFields/GoogleMapAutocomplete"
import {
  agencies,
  callSource,
  emergencyMedicalIncidents,
  emergencyMedicalServicesPriority,
  fireServiceIncidents,
  fireServicePriority,
  nonEmergencyPriority,
  nonEmergencyServiceIncidents,
  policeServiceIncidents,
  policeServicesPriority,
} from "../../enums/enums"
import useForm from "../../hooks/useForm"
import { ENDPOINTS, createAPIEndpoint } from "../../api/api"
import { toast } from "react-toastify"
import { getCurrentDateTimeFormatted } from "../../utils/GetDateTime"
import { getNextCallNumber } from "../../utils/GetNextCallNumber"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { generateEmailTemplate } from "../../utils/GetEmailTemplate"

function CallForm({ refetchItems, selectedQueueItem, setSelectedQueueItem }) {
  const getFreshModel = () => ({
    callNumber: "",
    callSource: "",
    incidentAddress: "",
    unit: "",
    nearestMainIntersection: "",
    phoneNumber: "",
    callerName: "",
    agency: "",
    incidentType: "",
    priority: "",
    callDetails: "",
    callTakerFirstName: "",
    callTakerLastName: "",
    dispatcherName: "",
    status: "N/A",
    time: "",
    isSaved: false,
  })

  const { values, setValues, errors, setErrors, handleInputChange } =
    useForm(getFreshModel)

  const [sendingToInstructor, setSendingToInstructor] = useState(false)

  const createNewCall = () => {
    createAPIEndpoint(ENDPOINTS.call)
      .fetch()
      .then((res) => {
        let nextCallNumber = getNextCallNumber(res.data)
        createAPIEndpoint(ENDPOINTS.call)
          .post({ ...getFreshModel(), callNumber: nextCallNumber })
          .then((newRecord) => setSelectedQueueItem(newRecord.data))
      })
  }

  useEffect(() => {
    createNewCall()
  }, [])

  const saveCall = () => {
    if ("_id" in values) {
      createAPIEndpoint(ENDPOINTS.call)
        .put(values._id, {
          ...values,
          time: getCurrentDateTimeFormatted(),
          isSaved: true,
          incidentAddress: JSON.stringify(values.incidentAddress),
        })
        .then((res) => {
          const parsedIncidentAddress = JSON.parse(
            res.data.incidentAddress === "" ? "{}" : res.data.incidentAddress
          )
          setSelectedQueueItem({
            ...res.data,
            incidentAddress: parsedIncidentAddress,
          })
          toast.success("Call Saved")
          refetchItems()
        })
    } else {
      createAPIEndpoint(ENDPOINTS.call)
        .post({
          ...values,
          time: getCurrentDateTimeFormatted(),
          isSaved: true,
          incidentAddress: JSON.stringify(values.incidentAddress),
        })
        .then((res) => {
          const parsedIncidentAddress = JSON.parse(
            res.data.incidentAddress === "" ? "{}" : res.data.incidentAddress
          )
          setSelectedQueueItem({
            ...res.data,
            incidentAddress: parsedIncidentAddress,
          })
          toast.success("Call Saved")
          refetchItems()
        })
    }
  }

  useEffect(() => {
    if (
      selectedQueueItem !== undefined &&
      Object.keys(selectedQueueItem).length !== 0
    ) {
      setValues({ ...selectedQueueItem })
    }
  }, [selectedQueueItem])

  const terminateCall = () => {
    createAPIEndpoint(ENDPOINTS.call)
      .put(values._id, {
        ...values,
        isSaved: false,
        incidentAddress: JSON.stringify(values.incidentAddress),
      })
      .then((res) => toast.success("Call Terminated"))
    setSelectedQueueItem({})
    setValues(getFreshModel())
    createNewCall()
    refetchItems()
  }

  const openMapWindow = () => {
    const mapURL = `https://www.google.com/maps?q=${encodeURIComponent(
      values.incidentAddress.description
    )}`
    const newWindow = window.open(mapURL, "Map Window", "width=800,height=500")
    newWindow.focus()
  }

  function openNewTabWithSameURL() {
    const currentURL = window.location.href // Get the current URL
    window.open(currentURL, "_blank") // Open the URL in a new tab
  }

  const sendToInstructor = () => {
    setSendingToInstructor(true)
    const emailData = {
      to: process.env.REACT_APP_EMAIL_ADDRESS, // Receiver's email address
      subject: `FreeCAD SUBMISSION ${values.dispatcherName}`,
      emailTemplate: generateEmailTemplate({
        ...values,
        incidentAddress: values.incidentAddress.description,
      }),
    }

    createAPIEndpoint(ENDPOINTS.sendEmail)
      .post(emailData)
      .then((res) => {
        toast.success("Email Sent Successfully")
        setSendingToInstructor(false)
      })
  }

  return (
    <>
      <Grid
        container
        direction='row'
        justifyContent='center'
        alignItems='flex-end'
        marginBottom={1}
        marginTop={1}
      >
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          padding={2}
          rowGap={2}
          xs={8}
        >
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            columnSpacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                disableElevation
                onClick={() => openNewTabWithSameURL()}
                variant='outlined'
              >
                New Call
              </Button>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
          </Grid>
          {/* image row */}
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            columnSpacing={2}
          >
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='Call number'
                defaultValue=''
                InputProps={{
                  readOnly: true,
                }}
                variant='filled'
                name='callNumber'
                value={values.callNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Box width='100%'>
                <img
                  style={{ maxWidth: "100%", height: "auto" }}
                  src={logo}
                  alt=''
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <DateTimeUpdate />
            </Grid>
          </Grid>
          {/* second row */}
          <Grid container rowSpacing={1} columnSpacing={2}>
            <Grid item xs={4}>
              <TextField
                variant='filled'
                select
                fullWidth
                label='Call Source'
                name='callSource'
                value={values.callSource}
                onChange={handleInputChange}
              >
                {callSource.map((val) => {
                  return <MenuItem value={val}>{val}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                columnSpacing={1}
              >
                <Grid item xs={10}>
                  {values && (
                    <GoogleMapAutocomplete
                      values={values}
                      setValues={setValues}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    disabled={values.incidentAddress === ""}
                    onClick={() => openMapWindow()}
                    sx={{ bgcolor: "#EEEEEE" }}
                    size='large'
                    aria-label='delete'
                  >
                    <LocationOnIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='Unit'
                defaultValue=''
                variant='filled'
                name='unit'
                value={values.unit}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          {/* third row */}
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='Nearest Main Intersection'
                defaultValue=''
                variant='filled'
                name='nearestMainIntersection'
                value={values.nearestMainIntersection}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='Phone Number'
                defaultValue=''
                variant='filled'
                name='phoneNumber'
                value={values.phoneNumber}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label="Caller's Name"
                defaultValue=''
                variant='filled'
                name='callerName'
                value={values.callerName}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          {/* fourth row */}
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant='filled'
                select
                label='Agency'
                name='agency'
                value={values.agency}
                onChange={handleInputChange}
              >
                {agencies.map((val) => {
                  return <MenuItem value={val}>{val}</MenuItem>
                })}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant='filled'
                select
                value={values.incidentType}
                name='incidentType'
                onChange={handleInputChange}
                label='Incident Type'
              >
                {values.agency === "Police Services" &&
                  policeServiceIncidents.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Fire Services" &&
                  fireServiceIncidents.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Emergency Medical Services" &&
                  emergencyMedicalIncidents.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Non-Emergency Services" &&
                  nonEmergencyServiceIncidents.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                variant='filled'
                select
                value={values.priority}
                name='priority'
                label='Priority'
                onChange={handleInputChange}
              >
                {values.agency === "Police Services" &&
                  policeServicesPriority.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Fire Services" &&
                  fireServicePriority.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Emergency Medical Services" &&
                  emergencyMedicalServicesPriority.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
                {values.agency === "Non-Emergency Services" &&
                  nonEmergencyPriority.map((val) => {
                    return <MenuItem value={val}>{val}</MenuItem>
                  })}
              </TextField>
            </Grid>
          </Grid>
          {/* fifth row */}
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={8}>
              <Typography sx={{ fontWeight: "medium" }}>
                Call Taker's Name
              </Typography>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='First'
                defaultValue=''
                variant='filled'
                name='callTakerFirstName'
                value={values.callTakerFirstName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                id='filled-required'
                label='Last'
                defaultValue=''
                variant='filled'
                name='callTakerLastName'
                value={values.callTakerLastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2} direction={"column"}>
                <TextField
                  id='filled-required'
                  label="Dispatcher's Name"
                  defaultValue=''
                  variant='filled'
                  name='dispatcherName'
                  value={values.dispatcherName}
                  onChange={handleInputChange}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={4}>
              <Button
                fullWidth
                disableElevation
                onClick={() => saveCall()}
                variant='contained'
              >
                Save Call
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={sendingToInstructor}
                onClick={() => sendToInstructor()}
                fullWidth
                disableElevation
                variant='contained'
              >
                Submit to Instructor
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                disableElevation
                onClick={() => terminateCall()}
                variant='contained'
              >
                Terminate Call
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          marginTop={2}
          padding={2}
          rowGap={2}
          xs={3}
        >
          <TextField
            fullWidth
            id='filled-required'
            label='Call Details'
            defaultValue=''
            variant='filled'
            rows={19}
            multiline
            name='callDetails'
            value={values.callDetails}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CallForm

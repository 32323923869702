import * as React from "react"
import GenericAccordion from "../genericAccordion/GenericAccordion"
import DispatchTable from "./DispatchTable"
import DispatchBenchmarkTable from "./DispatchBenchmarkTable"
import Queue from "./Queue"
import { Stack } from "@mui/material"

const accordionStyle = {
  width: "90%", // Set the desired width
  margin: "0 auto", // Center the accordion horizontally
  borderBottom: "2px solid #ccc", // Add a border at the bottom
}

export default function TableSection({
  queueItems,
  setSelectedQueueItem,
  selectedQueueItem,
  refetchItems,
}) {
  return (
    <div>
      <Stack
        marginTop={2}
        direction='column'
        justifyContent='center'
        alignItems='center'
        spacing={1}
      >
        <div style={accordionStyle}>
          <GenericAccordion
            title='Queue'
            component={
              <Queue
                queueItems={queueItems}
                setSelectedQueueItem={setSelectedQueueItem}
              />
            }
          />
        </div>
        <div style={accordionStyle}>
          <GenericAccordion
            title='Dispatch'
            component={
              <DispatchTable
                refetchItems={refetchItems}
                selectedQueueItem={selectedQueueItem}
                setSelectedQueueItem={setSelectedQueueItem}
              />
            }
          />
        </div>
        <div style={accordionStyle}>
          <GenericAccordion
            title='Dispatch Benchmark'
            component={
              <DispatchBenchmarkTable selectedQueueItem={selectedQueueItem} />
            }
          />
        </div>
      </Stack>
    </div>
  )
}

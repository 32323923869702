import React, { useEffect, useState } from "react"
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  TextField,
  Stack,
  Button,
} from "@mui/material"
import { statusValues } from "../../enums/enums"
import { ENDPOINTS, createAPIEndpoint } from "../../api/api"
import { toast } from "react-toastify"

const EditableTable = ({
  selectedQueueItem,
  refetchItems,
  setSelectedQueueItem,
}) => {
  const initialData = [
    {
      unit: "",
      status: "",
      dispatched: "",
      enroute: "",
      arrived_scene: "",
      depart_scene: "",
      arrived_dest: "",
      available: "",
    },
  ]

  const [data, setData] = useState(initialData)

  const refetchDispatchValues = async () => {
    try {
      const res = await createAPIEndpoint(ENDPOINTS.dispatch).fetch()
      if (res.data !== undefined) {
        const filteredData = res.data.filter(
          (item) => item.callId === selectedQueueItem._id
        )
        if (filteredData.length === 0) {
          setData([...initialData])
        } else {
          setData([...filteredData])
        }
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    setData([...data])
  }, [data])

  useEffect(() => {
    refetchDispatchValues()
  }, [selectedQueueItem])

  const handleEdit = (rowIndex, key, value) => {
    const updatedData = [...data]
    updatedData[rowIndex][key] = value
    setData(updatedData)
  }

  const addRow = () => {
    setData([...data, { ...initialData[0] }])
  }

  const updateQueue = () => {
    createAPIEndpoint(ENDPOINTS.call)
      .put(selectedQueueItem._id, {
        ...selectedQueueItem,
        unit: data.map((item) => item.unit).join(", "),
        status: data[0].status,
        incidentAddress: JSON.stringify(selectedQueueItem.incidentAddress),
      })
      .then((res) => {
        refetchItems()
        const parsedIncidentAddress = JSON.parse(
          res.data.incidentAddress === "" ? "{}" : res.data.incidentAddress
        )
        setSelectedQueueItem({
          ...res.data,
          incidentAddress: parsedIncidentAddress,
        })
      })
  }

  const saveDetails = async () => {
    try {
      await Promise.all(
        data.map(async (item) => {
          if ("_id" in item) {
            await createAPIEndpoint(ENDPOINTS.dispatch).put(item._id, {
              ...item,
              callId: selectedQueueItem._id,
            })
          } else {
            await createAPIEndpoint(ENDPOINTS.dispatch).post({
              ...item,
              callId: selectedQueueItem._id,
            })
          }
        })
      )
      updateQueue()
      toast.success("Details Saved")
      await refetchDispatchValues() // Wait for data to be refetched
    } catch (error) {
      // Handle any errors that occur during the API calls
      console.error("Error saving details:", error)
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='editable table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>Unit #</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Dispatched</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Enroute</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Arrived at Scene
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Departed from Scene
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                Arrived at Destination
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>Available</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                {Object.keys(initialData[0]).map((key) => (
                  <TableCell key={key}>
                    {key === "status" ? (
                      <Select
                        value={rowData[key]}
                        onChange={(e) =>
                          handleEdit(rowIndex, key, e.target.value)
                        }
                      >
                        {statusValues.map((val) => {
                          return <MenuItem value={val}>{val}</MenuItem>
                        })}
                      </Select>
                    ) : (
                      <TextField
                        fullWidth
                        sx={{ minWidth: 70 }}
                        id='filled-required'
                        defaultValue=''
                        variant='outlined'
                        value={rowData[key]}
                        onChange={(e) =>
                          handleEdit(rowIndex, key, e.target.value)
                        }
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        marginTop={2}
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={2}
      >
        <Button onClick={addRow} variant='outlined'>
          Add Row
        </Button>
        <Button disableElevation onClick={saveDetails} variant='contained'>
          Save Details
        </Button>
      </Stack>
    </>
  )
}

export default EditableTable

import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL

export const ENDPOINTS = {
    call: "call",
    dispatch: "dispatch",
    dispatchBenchmark: "dispatchBenchmark",
    sendEmail: "sendEmail"
};

export const createAPIEndpoint = (endpoint) => {
    let url = BASE_URL + "api/" + endpoint + "/";
    return {
        fetch: () => axios.get(url),
        fetchById: (id) => axios.get(url + id),
        post: (newRecord) => axios.post(url, newRecord),
        put: (id, updatedRecord) => axios.put(url + id, updatedRecord),
        delete: (id) => axios.delete(url + id),
    };
};

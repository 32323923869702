import { useEffect, useMemo, useState } from "react"
import { MaterialReactTable, useMaterialReactTable } from "material-react-table"
import { ENDPOINTS, createAPIEndpoint } from "../../api/api"
import { Button, Typography } from "@mui/material"

const Queue = ({ queueItems, setSelectedQueueItem }) => {
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "agency", //access nested data with dot notation
        header: "Agency",
        size: 150,
      },
      {
        accessorKey: "incidentAddress.description",
        header: "Address",
        size: 150,
      },
      {
        accessorKey: "incidentType", //normal accessorKey
        header: "Incident Type",
        size: 200,
      },
      {
        accessorKey: "priority", //normal accessorKey
        header: "Priority",
        size: 200,
      },
      {
        accessorKey: "unit",
        header: "Unit #",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "time",
        header: "Time",
        size: 150,
      },
      {
        accessorKey: "None",
        header: "Actions",
        size: 150,
        Cell: ({ renderedCellValue, row }) => (
          <Button
            disableElevation
            onClick={() => setSelectedQueueItem(row.original)}
            variant='contained'
          >
            View Details
          </Button>
        ),
      },
    ],
    []
  )

  return queueItems === undefined ? (
    <></>
  ) : (
    <MaterialReactTable
      columns={columns}
      data={queueItems.filter((item) => item.callNumber && item.isSaved)}
    />
  )
}

export default Queue

export function generateEmailTemplate(values) {
  // Define the fields to exclude from the email template
  const excludedFields = ['_id', 'isSaved', '__v'];

  // Filter out the excluded fields from the values object
  const filteredValues = Object.keys(values)
    .filter((fieldName) => !excludedFields.includes(fieldName))
    .reduce((obj, fieldName) => {
      obj[fieldName] = values[fieldName];
      return obj;
    }, {});



  // Define the email template structure with CSS styles
  const emailTemplate = `
      <html>
        <head>
          <style>
            body {
              font-family: Arial, sans-serif;
              background-color: #f4f4f4;
              margin: 0;
              padding: 0;
            }
            h1 {
              background-color: #007BFF;
              color: #fff;
              padding: 10px;
              text-align: center;
            }
            .container {
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
            }
            .field {
              border: 1px solid #ccc;
              padding: 10px;
              margin-bottom: 20px;
            }
            .field label {
              font-weight: bold;
            }
            .field p {
              margin: 0;
            }
          </style>
        </head>
        <body>
          <h1>FreeCAD Submission - ${filteredValues.dispatcherName}</h1>
          <div class="container">
            {{fields}}
          </div>
        </body>
      </html>
    `;

  // Generate content for each field dynamically
  const fields = Object.keys(filteredValues)
    .map((fieldName) => `
        <div class="field">
          <label>${fieldName}:</label>
          <p>${values[fieldName]}</p>
        </div>
      `)
    .join('');

  // Replace the {{fields}} placeholder with the dynamic content
  const fullEmailTemplate = emailTemplate.replace('{{fields}}', fields);

  return fullEmailTemplate;
}
